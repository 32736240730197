<template>
    <div class="chart-view" ref="chartView">

    </div>
</template>

<script>
const echarts = require('echarts');
import { barInit , pieInit , funnelInit } from './chartDataInit.js';

export default {
    props: {
        chartData: {
            type: Object,
            default(){
                return {};
            }
        },
        type: String
    },
    data(){
        return {
            myChart: null
        }
    },
    mounted(){
        this.myChart = echarts.init(this.$refs.chartView);
		this.myChart.on('click', (params) => {
			const {key} = this.chartData.title
			const {name: label} = params
			this.$router.push({
				name: 'person',
				query: {key, label}
			})
		})
        window.addEventListener('resize', this.resize);
    },
    destroyed(){
        window.removeEventListener('resize',this.resize);
    },
    watch: {
        chartData: {
            handler(val){
                // console.log('watch');
                this.$nextTick(() => {
                    this.renderChart()
                })
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        renderChart(){
            let data = {};
            switch(this.type){
                case 'bar':
                    data = barInit(this.chartData);
                break;
                case 'pie':
                    data = pieInit(this.chartData);
                break;
                case 'funnel':
                    data = funnelInit(this.chartData);
                break;
            }
            this.myChart.setOption(data);
        },
        resize(){
            this.myChart.resize();
        }
    },
}
</script>

<style lang="less" scoped>
.chart-view{
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}
</style>