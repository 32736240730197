<template>
	<div class="home">

		<div class="statistic">
			<ul class="left-ul">
				<li class="stat-item" v-for="(item, index) in statisticList" :key="index">
					<div class="count">
						<p class="mr15">{{ item.label }}</p>
						<p>{{ item.value }}</p>
					</div>
					<div class="line" v-if="index !== statisticList.length - 1"></div>
				</li>
			</ul>
			<ul class="right-ul">
				<li class="stat-item" v-for="(item, index) in statusList" :key="index">
					<div class="average">
						<span class="mr15">{{ item.label }}</span>
						<span>{{ item.value }}</span>
					</div>
					<div class="line" v-if="index !== statusList.length - 1"></div>
				</li>
			</ul>
		</div>
		<div class="container">
			<OrgTree :orgList="orgList" @input="orgIdChange" :onlyShowCourt="true"></OrgTree>
			<div class="chart">
				<div class="row">
					<div class="col ">
						<div class="chart-container bg-color-white col-left">
							<ChartView type="bar" :chartData="data2"></ChartView>
						</div>
						<div class="chart-container bg-color-white col-right">
							<ChartView type="pie" :chartData="data1"></ChartView>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col flex-grow-2">
						<div class="chart-container bg-color-white col-left">
							<ChartView type="bar" :chartData="data4"></ChartView>
						</div>
						<div class="chart-container bg-color-white col-right">
							<ChartView type="pie" :chartData="data3"></ChartView>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import ChartView from "@/common/chartView";
import EasyTree from "@/common/easyTree";
import {
  getEduHomecation,
  getHomeAge,
  getWoHomerkTime,
  getIncrease,
  proportion,
  getCadreStatus,
  getCadreAddStatus,
  getInCourt,
} from "@/api/statisticAPI.js";
import { getOrgList, userList, getJobList, delUser } from "@/api/hrAPI.js";
import OrgTree from "@/components/orgTree";

export default {
  components: {
    EasyTree,
    ChartView,
    OrgTree,
  },
  data() {
    return {
      statusList: [
        {
          label: "平均年龄",
          value: 0,
          info: "在职干部",
        },
        {
          label: "平均工作年限",
          value: 0,
          info: "在职干部",
        },
      ],
      data1: {
        title: {
          text: "干部学历统计",
		  key: 'qualification'
        },
        series: [
          {
			data: Object.entries(this.EnumType.education).map(([key, label]) => ({name:label, key, value: 0}))
          },
        ],
      },
      data2: {
        title: {
          text: "干部年龄统计",
		  key: 'age'
        },
        xAxis: {
          type: "category",
          data: [
            "35以下",
            "36-40岁",
            "41-45岁",
            "46-50岁",	
            "51-55岁",
            "56-60岁",
            "61岁以上",
          ],
        },
        series: [
          {
            data: [
              {
                name: "35岁以下",
                value: 0,
              },
              {
                name: "36-40岁",
                value: 0,
              },
              {
                name: "41-45岁",
                value: 0,
              },
              {
                name: "46-50岁",
                value: 0,
              },
              {
                name: "51-55岁",
                value: 0,
              },
              {
                name: "56-60岁",
                value: 0,
              },
              {
                name: "61岁以上",
                value: 0,
              },
            ],
          },
        ],
      },
      data3: {
        title: {
          text: "法官等级统计",
		  key: 'positionId'
        },
        series: [
          {
            data: [
              {
                name: "一级高级法官",
                value: 0,
              },
              {
                name: "二级高级法官",
                value: 0,
              },
              {
                name: "三级高级法官",
                value: 0,
              },
              {
                name: "四级高级法官",
                value: 0,
              },
              {
                name: "未入额",
                value: 0,
              },
            ],
          },
        ],
      },
      data4: {
        title: {
          text: "干部工作年限统计",
		  key: 'workYear'
        },
        xAxis: {
          type: "category",
          data: ["2年以下", "2-4年", "4-6年", "6-8年", "8-10年", "10年以上"],
        },
        series: [
          {
            data: [
              {
                name: "2年以下",
                value: 0,
              },
              {
                name: "2-4年",
                value: 0,
              },
              {
                name: "4-6年",
                value: 0,
              },
              {
                name: "6-8年",
                value: 0,
              },
              {
                name: "8-10年",
                value: 0,
              },
              {
                name: "10年以上",
                value: 0,
              },
            ],
            type: "line",
          },
        ],
      },
      averageData: {},
      timer: null,
      orgList: [],
    };
  },
  computed: {
    statisticList() {
      const list = [
        {
          label: "干部总数",
          value: 0,
        },
        {
          label: "在职",
          value: 0,
        },
        {
          label: "辞职",
          value: 0,
        },
        {
          label: "退休",
          value: 0,
        },
        {
          label: "调出",
          value: 0,
        },
        {
          label: "其他",
          value: 0,
        },
      ];
	  return list
    },
  },
  created() {
    this.init();
    this.getOrgList();
  },
  methods: {
    getOrgList() {
      getOrgList().then((res) => {
        this.orgList = res;
      });
    },
    orgIdChange(val) {
      this.$router.push({
        path: "/person",
        query: { orgId: val },
      });
    },
	camelize(key) {
		key = key.toLowerCase()
		const underlizeRE = /_(\w)/g
		return key.replace(underlizeRE, (_, c) => c ? c.toUpperCase() : '')
	},
    init() {
      getEduHomecation().then((res) => {
		this.data1.series[0].data.forEach(v => {
			v.value = +res[`${this.camelize(v.key)}Count`]
		})
      });
      getHomeAge().then((res) => {
        this.data2.series[0].data[0].value = res.lessThenThirtyFive;
        this.data2.series[0].data[1].value = res.thirtySixToForty;
        this.data2.series[0].data[2].value = res.fortyOneToFortyFive;
        this.data2.series[0].data[3].value = res.fortySixToFifty;
        this.data2.series[0].data[4].value = res.fiftyOneToFiftyFive;
        this.data2.series[0].data[5].value = res.fiftySixToSixty;
        this.data2.series[0].data[6].value = res.moreThenSixtyOne;
      });
      getInCourt().then((res) => {
        // console.log(res,'res');
        this.data3.series[0].data[0].value = res.FirstSeniorJudge;
        this.data3.series[0].data[1].value = res.SecondSeniorJudge;
        this.data3.series[0].data[2].value = res.ThirdSeniorJudge;
        this.data3.series[0].data[3].value = res.FourthSeniorJudge;
        this.data3.series[0].data[4].value = res.noRank;
      });
      getWoHomerkTime().then((res) => {
        // console.log('getWoHomerkTime',res);
        this.data4.series[0].data[0].value = res.lessThenTwo;
        this.data4.series[0].data[1].value = res.twoToFour;
        this.data4.series[0].data[2].value = res.fourToSix;
        this.data4.series[0].data[3].value = res.sixToEight;
        this.data4.series[0].data[4].value = res.eightToTen;
        this.data4.series[0].data[5].value = res.moreThenTen;
      });
      proportion().then((res) => {
        this.statusList[0].value = res.avgAge;
        this.statusList[1].value = res.avgWorkingYear;
        // this.statusList[2].value = res.qualificationPercent;
      });
      getCadreStatus().then((res) => {
        this.statisticList[0].value = res.totalCount;
        this.statisticList[1].value = res.beOnTheJobCount;
        this.statisticList[2].value = res.departCount;
        this.statisticList[3].value = res.retireCount;
        this.statisticList[4].value = res.callOutCount;
        this.statisticList[5].value = res.restCount;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  box-sizing: border-box;
  padding: 20px 30px;

  .statistic {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    height: 70px;

    .left-ul,
    .right-ul {
      width: 0;
      background-color: #c4ece0;
      border-radius: 10px;
      color: #101010;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      .stat-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        flex: 1;
        margin: 0 2%;

        .count {
          display: flex;
          margin-right: 20px;
        }

        .line {
          position: absolute;
          width: 2px;
          height: 30px;
          right: 0;
          background-color: #a5d3c5;
        }
        .average {
          width: 100%;
          text-align: center;
          .average-box {
            // .status {
            //     margin-top: 10px;
            //     color: #2F6052;
            //     font-size: 12px;
            // }
          }
        }
      }

      .stat-item:last-child {
        margin-right: 0;
      }
    }

    .left-ul {
      flex: 2;
      margin-right: 20px;
    }
  }

  .container {
    height: calc(100% - 70px);
    box-sizing: border-box;
    padding: 20px 30px;
    padding-left: 0;
    display: flex;

    .chart {
      flex: 1;
      height: 100%;
      // padding-top: 15px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .row:last-child {
        margin-top: 15px;
      }

      .row {
        flex: 1;
        display: flex;
        height: 0;

        .col {
          flex: 2;
          margin-right: 20px;
          display: flex;
          width: 0;
        }

        .col:last-child {
          margin-right: 0;
        }

        .chart-container {
          margin-right: 15px;
          width: 0;
        }

        .col-left {
          width: 990px;
        }

        .col-right {
          // flex: 4;
          width: 600px;
        }

        .chart-container:last-child {
          margin-right: 0;
        }

        .bg-color-white {
          background-color: #fff;
        }
      }

      .cadre-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 13px;

        .title {
          color: #464646;
          font-size: 18px;
          font-weight: bold;
          height: 20px;
          line-height: 20px;
        }

        .list-container {
          margin-top: 20px;
          height: calc(100% - 40px);
          overflow: hidden;
          position: relative;

          .list {
            transition: all 1.5s linear;

            .list-item {
              font-size: 12px;
              display: flex;
              align-items: center;
              height: 28px;
              line-height: 28px;
              margin-bottom: 10px;

              i {
                width: 10px;
                height: 10px;
                background-color: #ff9959;
                border-radius: 50%;
                margin-right: 10px;
              }

              .text {
              }

              .time {
                margin-left: auto;
                color: #999;
                // .name{
                //     width: 20px;
                //     overflow: hidden;
                //     white-space: normal;
                //     text-overflow: ellipsis;

                // }
              }
            }
          }
        }
      }
    }
  }
}
</style>