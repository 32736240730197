import { deepAssign } from '@/utils/handle.js';

export function barInit(options) {
    const chartData = {
        title: {
            text: "直方图示例",
        },
        tooltip: {
            trigger: 'item'
        },
        xAxis: {
            type: "category",
            data: [],
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                data: [],
                type: "bar",
            },
        ],
    };

    return deepAssign(chartData, options);
}

export function pieInit(options) {
    const chartData = {
        title: {
            text: "案件数字化加工统计图",
        },
        legend: {
            left: "center",
            bottom: '5%',
        },
        // color: [],
        tooltip: {
            trigger: "item",
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["35%", "50%"],
                data: [],
                label: {
                    formatter: `{b}\n{c}`,
                },
            },
        ],
    };

    return deepAssign(chartData, options);
}

export function funnelInit(options) {
    const chartData = {
        title: {
            text: "漏斗图",
        },
        tooltip: {
            trigger: "item",
        },
        series: [
            {
                type: "funnel",
                data: [],
                // label: {
                //     formatter: `{b}\n{c}`,
                // },
            },
        ],
    };

    return deepAssign(chartData, options);
}
